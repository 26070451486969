<template>
  <div>
    <el-button type="primary" @click="handleAddTag">Add Tag</el-button>
    <div class="tag-wrapper" v-for="(kb_tag, index) in this.value.tags" :key="index">
      <el-form label-position="top">
        <el-form-item label="Category">
          <el-input
            v-model="kb_tag.name"
            placeholder="Tag Category"
            @input="(val) => (kb_tag.name = kb_tag.name.toLowerCase())"
          />
        </el-form-item>
        <el-form-item label="Tags">
          <el-tag
            :key="index"
            v-for="(tag, index) in kb_tag.tags"
            closable
            :disable-transitions="false"
            @close="handleClose(kb_tag, index)"
            style="margin-left: 10px"
          >
            {{ tag }}
          </el-tag>
          <el-input
            class="input-new-tag"
            v-if="kb_tag.inputVisible"
            v-model="kb_tag.newTagValue"
            size="mini"
            @keyup.enter.native="handleInputConfirm(kb_tag)"
            @input="(val) => (kb_tag.newTagValue = kb_tag.newTagValue.toLowerCase())"
            @blur="handleInputConfirm(kb_tag)"
          >
          </el-input>
          <el-button
            style="margin-left: 10px"
            v-else
            class="button-new-tag"
            size="small"
            @click="showInput(index)"
            >+ New Tag</el-button
          >
        </el-form-item>
      </el-form>
      <el-button type="danger" @click="handleDeleteTagObject(index)">Delete</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value", "setting", "name", "mode", "modules"],
  data() {
    return {
      dynamicTags: [],
      tagCategory: "",
    };
  },
  methods: {
    handleClose(tagObject, index) {
      tagObject.tags.splice(index, 1);
    },

    showInput(index) {
      this.value.tags[index].inputVisible = true;
    },

    handleInputConfirm(tagObject) {
      let newTagValue = tagObject.newTagValue;
      if (newTagValue) {
        tagObject.tags.push(newTagValue);
      }
      tagObject.inputVisible = false;
      tagObject.newTagValue = "";
    },
    handleAddTag() {
      this.value.tags.push({
        name: "",
        tags: [],
        inputVisible: false,
        newTagValue: "",
      });
    },
    handleDeleteTagObject(index) {
      this.$confirm("Are you sure to delete this category?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.value.tags.splice(index, 1);
        })
        .catch(() => {});
    },
  },
  created() {
    if (!this.value[this.name]) {
      this.$set(this.value, this.name, _.cloneDeep(this.setting.default));
    }
  },
};
</script>

<style>
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px !important;
  margin-left: 10px !important;
  vertical-align: bottom;
}

.tag-wrapper {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
}
</style>
